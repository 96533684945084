import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FacadeService} from '../../shared/service/facade/facade.service';

@Component({
  selector: 'app-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.css']
})
export class RequestResetPasswordComponent implements OnInit {

  usernameForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private facadeService: FacadeService,
    private translate: TranslateService) {
  }
  usernameValidated = false;

  ngOnInit(): void {
    this.formBuilderValidations();
  }

  private formBuilderValidations() {
    this.usernameForm = this.formBuilder.group({
        username: ['', [Validators.required]]
      }
    );
  }

  onSubmit() {
    if (this.usernameForm.valid) {
      this.facadeService.resetPassword(this.usernameForm.get('username').value)
        .subscribe({
          next: ignoreProperty => {
            this.usernameValidated = true;
          },
          error: ignoreProperty => {
            alert(this.translate.instant('login.resetPassword.message.error.connectionError'));
          }
        });
    }
  }

  backToMenu() {
    this.router.navigateByUrl('/login').then();
  }
}
