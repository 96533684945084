<div class="col-md-12 shadowbox pb-3">

  <div class="grid">

    <div class="col-12">

      <table mat-table class="table" [dataSource]="prestationDataSource" multiTemplateDataRows>

        <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedPrestationColumns">

          <th mat-header-cell *matHeaderCellDef>

            <h5 class="heading my-3"><i
              class="fas fa-calendar-check"></i>&nbsp;&nbsp;{{ 'prestations.validation.title' | translate }}</h5>
          </th>

          <td mat-cell *matCellDef="let prestation;">

            <div class="row justify-content-between">

              <div>

                <span class="heading">#{{(prestation | asType :Prestation)?.id}}</span>&nbsp;|&nbsp;

                <span *ngIf="(asPrestation(prestation))?.workerFirstName || asPrestation(prestation)?.workerLastName">
                  <i class="fas fa-users"></i>&nbsp;&nbsp;{{asPrestation(prestation)?.workerLastName}}&nbsp;{{asPrestation(prestation)?.workerFirstName}}
                </span>

                &nbsp;|&nbsp;&nbsp;<i class="far fa-calendar-alt"></i>&nbsp;{{ 'prestations.validation.week' | translate }}&nbsp;{{getWeekNumber(prestation)}}

              </div>

              <div class="col-3 text-right">

                <div *ngIf="expandedElement?.id === asPrestation(prestation).id && !asPrestation(prestation).isValidated"
                     [hidden]="asPrestation(prestation).isDateNotMatched || asPrestation(prestation).isMissMatched">

                  <button
                    mat-flat-button
                     (click)="validatePrestation(prestation)">
                    <i class="far fa-check-square"></i>&nbsp;&nbsp;{{ 'prestations.validation.validateAll' | translate }}
                  </button>

                </div>

                <div *ngIf="asPrestation(prestation).isDateNotMatched" class=warningColor>
                  <i class="fas fa-exclamation-triangle"></i>
                  &nbsp;{{ 'prestations.validation.status.DATE_MISMATCH' | translate }}
                </div>

                <div *ngIf="asPrestation(prestation).isMissMatched" class=warningColor>
                  <i class="fas fa-exclamation-triangle"></i>
                  &nbsp;{{ 'prestations.validation.status.DECISION_MISMATCH' | translate }}
                </div>

              </div>

            </div>

          </td>

        </ng-container>

        <ng-container matColumnDef="expandedDetail">

          <td mat-cell *matCellDef="let prestation" [attr.colspan]="displayedPrestationColumns.length">

            <div *ngIf="expandedElement?.id === prestation?.id" class="element-detail"
                 [@detailExpand]="prestation == expandedElement ? 'expanded' : 'collapsed'">

              <table mat-table [dataSource]="prestationPeriodDataSource" class="table text-center">

                <!-- Status Color -->
                <ng-container matColumnDef="statusColor">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td [class.validate]="asPeriod(period).isValidated"
                      [class.warning]="asPeriod(period).isDateNotMatched"
                      [class.toValidate]="!asPeriod(period).isValidated && !asPeriod(period).isDateNotMatched || asPeriod(period).isCustomerRejected"
                      mat-cell
                      *matCellDef="let period">
                    <div *ngIf="asPeriod(period).isCustomerValidated || asPeriod(period).isCustomerRejected"
                         [class.activeColor]="asPeriod(period).isCustomerValidated"
                         [class.middleColor]="!asPeriod(period).isProcessed"
                         [class.inactiveColor]="asPeriod(period).isCustomerRejected"
                         [class.warningColor]="asPeriod(period).isDateNotMatched">
                      &nbsp;
                    </div>
                  </td>
                </ng-container>

                <!--  #   -->
                <ng-container matColumnDef="number">
                  <th mat-header-cell *matHeaderCellDef>#</th>
                  <td mat-cell *matCellDef="let period">{{(period | asType :PrestationPeriod).id}}</td>
                </ng-container>

                <!--  plannedStartDate  -->
                <ng-container matColumnDef="plannedDate">
                  <th mat-header-cell
                      *matHeaderCellDef>{{'prestations.validation.planned' | translate}}
                  </th>
                  <td mat-cell *matCellDef="let period">
                    {{asPeriod(period).plannedStartDate | date:'dd/MM/yyyy'}}
                    <i class="fas fa-clock"></i>
                    {{asPeriod(period).plannedStartDate | date:'HH:mm'}}&nbsp;-&nbsp;{{asPeriod(period).plannedEndDate | date:'HH:mm'}}
                  </td>
                </ng-container>

                <!--  actualStartDate  -->
                <ng-container matColumnDef="actualWorkerDate">
                  <th mat-header-cell
                      *matHeaderCellDef>{{'prestations.validation.workerValidationDate' | translate}}
                  </th>

                  <td mat-cell *matCellDef="let period">
                    <div *ngIf="asPrestation(prestation).isNursing">
                      <div *ngIf="asPeriod(period).isWorkerValidated">
                        {{asPeriod(period).actualWorkerStartDate | date:'dd/MM/yyyy'}}
                        <i class="fas fa-clock"></i>
                        {{asPeriod(period).actualWorkerStartDate | date:'HH:mm'}}&nbsp;-&nbsp;{{asPeriod(period).actualWorkerEndDate | date:'HH:mm'}}
                      </div>
                      <div *ngIf="asPeriod(period).isWorkerRejected" class="inactiveColor">
                        {{ 'prestations.validation.message.workerRejected' | translate }}
                      </div>
                      <div *ngIf="!asPeriod(period).isWorkerValidated && !asPeriod(period).isWorkerRejected">
                        {{ 'prestations.validation.message.workerNotValidated' | translate }}
                      </div>
                    </div>
                    <div *ngIf="!asPrestation(prestation).isNursing">/</div>
                  </td>

                </ng-container>


                <ng-container matColumnDef="actualCustomerDate">
                  <th mat-header-cell
                      *matHeaderCellDef>{{'prestations.validation.customerValidationDate' | translate}}
                  </th>

                  <td mat-cell *matCellDef="let period">
                    <div *ngIf="asPeriod(period).isCustomerValidated"
                         [class.warningColor]="asPeriod(period).isDateNotMatched || asPeriod(period).isMissMatched">
                      {{asPeriod(period).actualCustomerStartDate | date:'dd/MM/yyyy'}}
                      <i class="fas fa-clock"></i>
                      {{asPeriod(period).actualCustomerStartDate | date:'HH:mm'}}&nbsp;-&nbsp;{{asPeriod(period).actualCustomerEndDate | date:'HH:mm'}}
                    </div>
                    <div *ngIf="asPeriod(period).isCustomerRejected" class="inactiveColor">
                      {{ 'prestations.validation.message.customerRejected' | translate }}
                    </div>
                    <div *ngIf="!asPeriod(period).isCustomerValidated && !asPeriod(period).isCustomerRejected">
                      {{ 'prestations.validation.message.customerNotValidated' | translate }}
                    </div>
                  </td>

                </ng-container>

                <!-- Horeca environment -->
                <ng-container matColumnDef="actualDate">
                  <th mat-header-cell
                      *matHeaderCellDef>{{'prestations.validation.validationDate' | translate}}
                  </th>

                  <td mat-cell *matCellDef="let period">
                    <div *ngIf="asPeriod(period).isCustomerValidated">
                      {{asPeriod(period).actualStartDate | date:'dd/MM/yyyy'}}
                      <i class="fas fa-clock"></i>
                      {{asPeriod(period).actualStartDate | date:'HH:mm'}}&nbsp;-&nbsp;{{asPeriod(period).actualEndDate | date:'HH:mm'}}
                    </div>
                    <div *ngIf="asPeriod(period).isRejected || asPeriod(period).isCustomerRejected"
                         [class.inactiveColor]="asPeriod(period).isRejected || asPeriod(period).isCustomerRejected">
                      {{ 'prestations.validation.message.rejected' | translate }}
                    </div>
                    <div *ngIf="!asPeriod(period).isCustomerValidated && !(asPeriod(period).isRejected || asPeriod(period).isCustomerRejected)" class="middleColor">
                      {{ 'prestations.validation.message.customerNotValidated' | translate }}
                    </div>
                  </td>
                </ng-container>

                <!--  validate Button  -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let period" class="text-right">
                    <div *ngIf="asPeriod(period).isModifiable">
                      <button mat-flat-button
                              style="width : 13em;"
                              (click)="validatePeriod(period, prestation)">
                        <i
                          class="far fa-check-square"></i>&nbsp;&nbsp;{{ 'prestations.validation.period.validatePeriod' | translate }}
                      </button>
                      &nbsp;
                      <button mat-flat-button
                              style="width : 13em;"
                              class="orangeButton"
                              (click)="rejectPeriod(period, prestation)">
                        <i
                          class="fas fa-times-circle"></i>&nbsp;&nbsp;{{ 'prestations.validation.period.rejectPeriod' | translate }}
                      </button>
                    </div>

                    <!--  Status  -->
                    <div *ngIf="!asPeriod(period).isModifiable"
                         [class.activeColor]="asPeriod(period).isCustomerValidated"
                         [class.inactiveColor]="asPeriod(period).isCustomerRejected">
                      <i class="fas "
                         [class.fa-check-circle]="asPeriod(period).isCustomerValidated"
                         [class.fa-times-circle]="asPeriod(period).isCustomerRejected">
                      </i>
                      &nbsp;{{ 'prestations.validation.period.status.' + getStatus(period) | translate }}
                    </div>

                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedPrestationPeriodColumns"></tr>
                <tr mat-row *matRowDef="let period; columns: displayedPrestationPeriodColumns;"></tr>

              </table>

            </div>

          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedPrestationColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedPrestationColumns;"
            class="element-row"
            [class.expanded-row]="expandedElement === element"
            (click)="setExpandedElement(element)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

      </table>

      <mat-paginator [pageSizeOptions]="[25, 50, 75]" showFirstLastButtons></mat-paginator>

    </div>

  </div>

</div>
