<div class="bg-horeca-log">

  <div class="container">

    <div class="row">

      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

        <div class="card card-signin my-5 shadowbox">

          <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <!-- Brand Logo -->
            <div class="logoHS">
              <img src="../assets/img/HS_Horeca_2022_RVB_72dpi.png" alt="Human Support Horeca">
            </div>

            <!-- Title -->
            <div class="card-img-top bg-color-1">

              <h4 class="text-center">{{ 'register.title' | translate }}</h4>

            </div>

            <div class="card-body">

              <div class="form-group">

                <label for="username">{{ 'register.username' | translate }}</label>
                <input class="form-control" formControlName="username" type="text" id="username" #username required readonly>

              </div>

              <div class="form-group">

                <label for="password">{{ 'register.password' | translate }}</label>
                <input class="form-control" formControlName="password" type="password" id="password" #password
                       required>

              </div>

              <div class="form-group">

                <label for="password">{{ 'register.confirmPassword' | translate }}</label>
                <input class="form-control" formControlName="confirmPassword" type="password" id="confirmPassword"
                       #confirmPassword
                       required>

              </div>

              <button class="btn btn-lg btn-danger btn-block my-4"
                      type="submit">{{ 'register.save' | translate }}</button>

            </div>

          </form>

        </div>

      </div>

    </div>

  </div>

</div>
