import {PrestationPeriod} from './prestation-period.model';

export class Prestation {
  id: number;
  requestId: number;
  customerName: string;
  workerFirstName: string;
  workerLastName: string;
  jointCommissionCode: string;
  startDate: Date;
  endDate: Date;
  isValidated: boolean;
  isDateNotMatched: boolean;
  isMissMatched: boolean;
  isNursing: boolean;
  periods: PrestationPeriod[] = [];
}
