import {Serializer} from '../serializer.interface';
import {OperatingUnit} from './operating-unit.model';
import {AddressSerializer} from '../address/address.serializer';
import {Address} from '../address/address.model';

export class OperatingUnitSerializer implements Serializer {
  private addressSerializer: AddressSerializer = new AddressSerializer();

  fromJson(json: any): OperatingUnit {
    const operatingUnit: OperatingUnit = new OperatingUnit();
    let address: Address = new Address();

    operatingUnit.name = json.name ? json.name : "/";
    operatingUnit.phone = json.phone ? json.phone : "/";
    operatingUnit.mail = json.mail ? json.mail : "/";

    const jsonAddress = json.address;
    if (jsonAddress) {
      address = this.addressSerializer.fromJson(jsonAddress);
    }
    operatingUnit.address = address;

    return operatingUnit;
  }

  toJson(operatingUnit: OperatingUnit): any {
    return {};
  }
}
