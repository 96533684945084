import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Invoice} from './shared/model/invoice.model';
import {MatPaginator} from '@angular/material/paginator';
import {FacadeService} from '../shared/service/facade/facade.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  invoices = new MatTableDataSource<Invoice>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['invoiceId', 'dueDate', 'invoicingDate', 'total', 'download'];

  constructor(private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.getInvoices();
  }

  asInvoice(invoice: Invoice): Invoice {
    return invoice;
  }

  private getInvoices(): void {
    this.facadeService.listInvoices().subscribe({
        next: (invoices: Invoice[]) => {
          this.invoices = new MatTableDataSource<Invoice>(invoices);
          this.invoices.paginator = this.paginator;
        },
        error: ignore => {
          this.openSnackBar(this.translate.instant('invoices.message.error.invoices'), 'customSnackError');
        }
      }
    );
  }

  downloadDocument(invoice: Invoice) {
    this.facadeService.getInvoicePdf(invoice, this.translate.currentLang).subscribe({
        next: data => {
          const file = new Blob([data], {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        },
        error: ignore => {
          this.openSnackBar(this.translate.instant('invoices.message.error.pdf'), 'customSnackError');
        }
      }
    );
  }

  private openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 5000,
      panelClass: [pC]
    });
  }
}
