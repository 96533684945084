import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ContractSerializer} from '../model/contract.serializer';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Contract} from '../model/contract.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  private serviceURL = environment.contract_service_URL + 'contracts';
  private contractSerializer: ContractSerializer = new ContractSerializer();

  constructor(private http: HttpClient) {
  }

  listContracts(): Observable<any> {
    return this.http.get<Contract[]>(`${this.serviceURL}/app/customer/contracts`).pipe(
      map((data) => this.convertData(data))
    );
  }

  downloadPdf(contract: Contract, language: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('language', String(language));
    return this.http.get(`${this.serviceURL}/app/customer/contract/${contract.id}/pdf`, {params: params, responseType: 'blob'});
  }

  private convertData(data: any): Contract[] {
    return data.map(contract =>
      this.contractSerializer.fromJson(contract));
  }
}
