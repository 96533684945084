import {Period, Request} from './request.model';
import {DateSerializer} from '../../../shared/model/date-serializer';
import {Serializer} from '../../../shared/model/serializer.interface';
import {WorkerSerializer} from '../../../shared/model/worker/worker.serializer';
import {Worker} from '../../../shared/model/worker/worker.model';

export class RequestSerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();
  private workerSerializer: WorkerSerializer = new WorkerSerializer();

  fromJson(json: any): any {
    const request: Request = new Request();
    const periods: Period[] = [];

    request.id = json.id;
    request.startDate = this.dateSerializer.fromJson(json.startDate);
    request.endDate = this.dateSerializer.fromJson(json.endDate);
    request.costCenter = json.costCenter === '' ? null : json.costCenter;
    request.description = json.description;
    request.workFunctionId = json.workFunctionId;
    request.workFunction = json.workFunction;
    request.activeContract = json.activeContract;
    request.totalHours = json.totalHours;
    request.totalMinutes = json.totalMinutes;
    request.isModifiable = json.modifiable;
    
    const jsonPeriods = json.periods;
    if (jsonPeriods) {
      jsonPeriods.forEach(
        jsonPeriod => {
          periods.push(this.periodFromJson(jsonPeriod));
        });
    }
    request.periods = periods;

     // Check if request's' first period's start date is < new Date() so that it can still be deleted //
     const sortedPeriods = periods.sort((a,b) => a.startDate.getTime() - b.startDate.getTime());
     const firstStartDate = sortedPeriods[0]?.startDate;

     if (firstStartDate < new Date()){
        request.isModifiable = false;
      } 
   

    let worker: Worker = new Worker();
    if (json.worker) {
      worker = this.workerSerializer.fromJson(json.worker);
    }
    request.worker = worker;

    return request;
  }

  private periodFromJson(jsonPeriod: any): Period {
    const period = new Period();

    period.id = jsonPeriod.id;
    period.startDate = this.dateSerializer.fromJson(jsonPeriod.startDate);
    period.endDate = this.dateSerializer.fromJson(jsonPeriod.endDate);
    period.description = jsonPeriod.description;

    return period;
  }

  toJson(request: Request) {
    const periods: any[] = [];
    request.periods.forEach(
      period => {
        periods.push(this.periodToJson(period));
      }
    );

    return {
      id: request.id,
      startDate: this.dateSerializer.toJson(request.startDate),
      endDate: this.dateSerializer.toJson(request.endDate),
      costCenter: request.costCenter === '' ? null : request.costCenter,
      description: request.description,
      workFunctionId: request.workFunctionId,
      workFunction: request.workFunction,
      periods: periods,
      workerId: request.worker.id,
      totalHours: request.totalHours,
      totalMinutes: request.totalMinutes


      // status: request.status,
      // operatingUnitId: request.operatingUnitId
      // createdByCustomer: request.createdByCustomer
    };
  }

  private periodToJson(period: Period) {
    return {
      id: period.id,
      startDate: this.dateSerializer.dateWithTimeToJson(period.startDate),
      endDate: this.dateSerializer.dateWithTimeToJson(period.endDate),
      description: period.description
    };
  }
}
