import {Component, OnInit} from '@angular/core';
import {Customer} from './shared/model/customer.model';
import {Country} from '../shared/model/address/address.model';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
  customer: Customer = new Customer();
  countries: Country[] = [];
  countryName: string;
  billingCountryName: string;

  constructor(private route: ActivatedRoute) {
    this.customer = this.route.snapshot.data['customer'];

  }

  ngOnInit() {
    this.countryName = this.customer.addressCountry.nameEN;
    this.billingCountryName = this.customer.billingCountry.nameEN;
  }
}
