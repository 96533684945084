import {Address} from './address.model';
import {Serializer} from '../serializer.interface';

export class AddressSerializer implements Serializer {

  fromJson(json: any): Address {
    const address = new Address();
    address.street = json.street ? json.street : "/";
    address.number = json.number ? json.number : "/";
    address.box = json.box ? json.box : "/";
    address.zip = json.zip ? json.zip : "/";
    address.city = json.city ? json.city : "/";
    address.country = json.country ? json.country : "/";

    return address;
  }

  toJson(address: Address): any {
    return {
      street: address.street,
      number: address.number,
      box: address.box,
      zip: address.zip,
      city: address.city,
      country: address.country
    };
  }
}
