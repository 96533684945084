<form #validateForm="ngForm" (ngSubmit)="onValidate(validateForm)">

  <h5 mat-dialog-title><i
    class="fas fa-check"></i>&nbsp;#{{prestationPeriod.id}}&nbsp;&nbsp;{{prestation.customerName}}&nbsp;-&nbsp;{{prestation.workerLastName}}&nbsp;{{prestation.workerFirstName}}
  </h5>

  <mat-dialog-content *ngIf="prestationPeriod.isProcessed">
    <div class="row-form">
      <div class="col">
        <p>{{ 'prestations.validation.dialog.alreadyValidated' | translate }}</p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-content *ngIf="!prestationPeriod.isProcessed">
    <div class="form-row">

      <div class="col">

        <div class="matFlex">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'prestations.validation.dialog.startDate' | translate }}</mat-label>
            <input matInput type="date" id="startDate" name="startDate"
                   [ngModel]="prestationFormModel?.startDate | date:'yyyy-MM-dd'"
                   required readonly>
          </mat-form-field>
        </div>

      </div>

      <div class="col-6">

        <div class="matFlex">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'prestations.validation.dialog.startHour' | translate }}</mat-label>
            <input matInput type="time" id="startHour" name="startHour"
                   [ngModel]="prestationFormModel?.startHour | date:'HH:mm'"
                   required (blur)="onBlurStartHour(validateForm)">
          </mat-form-field>
        </div>

      </div>

    </div>

    <div class="form-row">

      <div class="col">

        <div class="matFlex">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'prestations.validation.dialog.endDate' | translate }}</mat-label>
            <input matInput type="date" id="endDate" name="endDate"
                   [ngModel]="prestationFormModel?.endDate | date:'yyyy-MM-dd'"
                   required readonly>
          </mat-form-field>
        </div>

      </div>

      <div class="col-6">

        <div class="matFlex">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'prestations.validation.dialog.endHour' | translate }}</mat-label>
            <input matInput type="time" id="endHour" name="endHour"
                   [ngModel]="prestationFormModel?.endHour | date:'HH:mm'"
                   required (blur)="onBlurEndHour(validateForm)">
          </mat-form-field>
        </div>

      </div>

    </div>

    <div class="form-row">

      <div class="col">

        <div class="matFlex">
          <mat-form-field appearance="legacy">
            <mat-label>{{ 'prestations.validation.dialog.totalHours' | translate }}</mat-label>
            <input matInput type="text"
                   [value]="prestationFormModel?.totalWorkedHours | number:'1.2-2'" readonly>
          </mat-form-field>
        </div>

      </div>

      <div class="col">

        <div class="matFlex">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'prestations.validation.dialog.break' | translate }}</mat-label>
            <mat-select [ngModel]="prestationFormModel.breakDuration | date:'HH:mm'" id="breakDuration"
                        name="breakDuration" required (blur)="onBlurBreakDuration(validateForm)">
              <mat-option *ngFor="let breakDuration of breakDurationValues;"
                          [value]="breakDuration">
                {{ breakDuration }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>

    </div>

  </mat-dialog-content>

  <mat-dialog-actions class="mb-1 justify-content-between">

    <button mat-flat-button type="button" color="accent" mat-dialog-close="false" (click)="closeDialog()">
      <i class="far fa-times-circle"></i>&nbsp;&nbsp;{{ 'prestations.validation.dialog.close' | translate }}
    </button>
    <button mat-flat-button color="primary" type="submit" [disabled]="clicked">
      <i class="fas fa-check-circle"></i>&nbsp;&nbsp;{{ 'prestations.validation.dialog.save' | translate }}
    </button>

  </mat-dialog-actions>

</form>
