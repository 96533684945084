import {Serializer} from '../../../shared/model/serializer.interface';
import {WorkerSerializer} from '../../../shared/model/worker/worker.serializer';
import {DateSerializer} from '../../../shared/model/date-serializer';
import {Contract} from './contract.model';
import {Worker} from '../../../shared/model/worker/worker.model';

export class ContractSerializer implements Serializer {
  private workerSerializer: WorkerSerializer = new WorkerSerializer();
  private dateSerializer: DateSerializer = new DateSerializer();

  fromJson(json: any): Contract {
    const contract: Contract = new Contract();

    contract.id = json.id;
    contract.startDate = this.dateSerializer.fromJson(json.startDate);
    contract.endDate = this.dateSerializer.fromJson(json.endDate);
    contract.contractType = json.contractType;
    contract.costCenter = json.costCenter;

    let worker: Worker = new Worker();
    if (json.worker) {
      worker = this.workerSerializer.fromJson(json.worker);
    }
    contract.worker = worker;

    return contract;
  }

  toJson(contract: Contract) {}
}
