import {Worker} from '../../../shared/model/worker/worker.model';

export enum ContractType {
  SELECTION = 'SELECTION',
  PAYROLL = 'PAYROLL'
}

export class Contract {
  id: number;
  startDate: Date;
  endDate: Date;
  contractType: ContractType;
  costCenter: string;
  worker: Worker;
}
