import { DateSerializer } from "../date-serializer";
import { Serializer } from "../serializer.interface";
import { WorkerSerializer } from "../worker/worker.serializer";
import { NonAvailability } from "./nonAvailability.model";
import { Worker } from "../worker/worker.model";


export class NonAvailabilitySerializer implements Serializer {
  private workerSerializer: WorkerSerializer = new WorkerSerializer();
  private dateSerializer: DateSerializer = new DateSerializer();

  fromJson(json: any): any {
    const nonAvailability: NonAvailability = new NonAvailability();
    let worker: Worker = new Worker();

    nonAvailability.id = json.id;
    const jsonWorker = json.worker;
    if (jsonWorker) {
      worker = this.workerSerializer.fromJson(jsonWorker);
    }
    nonAvailability.worker = worker;
    nonAvailability.startDate = this.dateSerializer.fromJson(json.startDate);
    nonAvailability.endDate = this.dateSerializer.fromJson(json.endDate);
    nonAvailability.until = this.dateSerializer.fromJson(json.until);
    nonAvailability.active = json.active;
    nonAvailability.frequency = json.frequency;
    nonAvailability.count = json.count;
    nonAvailability.allDay = json.allDay;

    return nonAvailability;
  }

  toJson(nonAvailability: NonAvailability): any {
    return {
      id: nonAvailability.id,
      worker: this.workerSerializer.toJson(nonAvailability.worker),
      startDate: this.dateSerializer.dateWithTimeToJson(nonAvailability.startDate),
      endDate: this.dateSerializer.dateWithTimeToJson(nonAvailability.endDate),
      until: this.dateSerializer.toJson(nonAvailability.until),
      active: nonAvailability.active,
      frequency: nonAvailability.frequency,
      count: nonAvailability.count,
      allDay: nonAvailability.allDay
    };
  }
}
