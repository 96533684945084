export class WorkFunction {
  id: number;
  category: number;
  code: string;
  nameDE: string;
  nameEN: string;
  nameFR: string;
  nameNL: string;
  nameTranslate: string;
}
