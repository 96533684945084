import {Serializer} from '../../../shared/model/serializer.interface';
import {AddressSerializer} from '../../../shared/model/address/address.serializer';
import {Customer} from './customer.model';
import {Address} from '../../../shared/model/address/address.model';
import {OperatingUnitSerializer} from '../../../shared/model/operating-unit/operating-unit.serializer';
import {CountrySerializer} from '../../../shared/model/country/country.serializer';
import { TranslateService } from '@ngx-translate/core';

export class CustomerSerializer implements Serializer {
  private addressSerializer: AddressSerializer = new AddressSerializer();
  private operatingUnitSerializer: OperatingUnitSerializer = new OperatingUnitSerializer();
  private countrySerializer: CountrySerializer = new CountrySerializer();
  translate: TranslateService

  fromJson(json: any): Customer {


    const customer: Customer = new Customer();

    customer.id = json.id;
    customer.enterpriseNumber = json.enterpriseNumber ? json.enterpriseNumber : "/";
    customer.name = json.name ? json.name : "/";
    customer.prefix = json.prefix ? json.prefix : "/";

    if (json.costCenter !== '') {
      customer.costCenter = json.costCenter.split(';');
    }
    else {
      customer.costCenter = [""];
    }


    if (json.address) {
      customer.address = this.addressSerializer.fromJson(json.address);
    } else {
      customer.address = new Address();
    }

    if (json.billingAddress) {
      customer.billingAddress = this.addressSerializer.fromJson(json.billingAddress);
    } else {
      customer.billingAddress = new Address();
    }

    customer.operatingUnit = this.operatingUnitSerializer.fromJson(json.operatingUnit);
    customer.billingCountry = this.countrySerializer.fromJson(json.billingCountry);
    customer.addressCountry = this.countrySerializer.fromJson(json.addressCountry);


    return customer;
  }

  toJson(customer: Customer): any {
    return {};
  }
}
