import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {PrestationPeriodSerializer} from '../model/prestation-period.serializer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PrestationPeriod} from '../model/prestation-period.model';

@Injectable({
  providedIn: 'root'
})
export class PrestationPeriodService {
  private serviceURL = environment.prestation_service_URL + 'periods';
  private prestationPeriodSerializer: PrestationPeriodSerializer = new PrestationPeriodSerializer();

  constructor(private http: HttpClient) {
  }

  validate(period: PrestationPeriod): Observable<any> {
    return this.http.post<PrestationPeriod>(this.serviceURL + '/customer/app/validate',
      this.prestationPeriodSerializer.toJson(period)).pipe(
      map((data) => this.prestationPeriodSerializer.fromJson(data))
    );
  }

  reject(period: PrestationPeriod, file: File): Observable<any> {

    const formData = new FormData();
    if (file != null) {
      formData.append('file', file, file.name);
    }
    formData.append('periodId', String(period.id));
    formData.append('justificationCode', period.customerJustificationCode);
    formData.append('description', period.description);

    return this.http.post(this.serviceURL + '/customer/app/reject', formData, {responseType: 'text'}).pipe(
      map((data) => this.prestationPeriodSerializer.fromJson(data))
    );
  }
}
