import { WorkFunction } from 'src/app/shared/model/work-function/work-function.model';
import {Worker} from '../../../shared/model/worker/worker.model';


export class Request {
  id: number;
  startDate: Date;
  endDate: Date;
  costCenter: string;
  periods: Period[] = [];
  description: string;
  workFunctionId: number;
  workFunction: WorkFunction;
  activeContract = false;
  isModifiable = true;
  worker?: Worker;
  totalHours: number;
  totalMinutes: number;

  getColorByStatus(): string {
    if (!this.activeContract) {
      return '#FF9D00';
    } else {
      return '#26C84C';
    }
  }
}

export class Period {
  id: number;
  startDate: Date;
  endDate: Date;
  description: string;
}
