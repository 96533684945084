import {Injectable, Injector} from '@angular/core';
import {InvoiceService} from '../../../invoices/shared/service/invoice.service';
import {Observable} from 'rxjs';
import {CustomerService} from '../../../customer/shared/service/customer.service';
import {ContractService} from '../../../contracts/shared/service/contract.service';
import {Contract} from '../../../contracts/shared/model/contract.model';
import {Invoice} from '../../../invoices/shared/model/invoice.model';
import {RequestService} from '../../../request/shared/service/request.service';
import {UserService} from '../../../user/shared/service/user.service';
import {Request} from '../../../request/shared/model/request.model';
import {PrestationService} from '../../../prestations/shared/service/prestation.service';
import {PrestationPeriodService} from '../../../prestations/shared/service/prestation-period.service';
import {Prestation} from '../../../prestations/shared/model/prestation.model';
import {PrestationPeriod} from '../../../prestations/shared/model/prestation-period.model';
import {Login} from '../../../login/shared/model/login.model';
import { NonAvailability } from '../../model/non-availability/nonAvailability.model';
import { NonAvailabilityService } from 'src/app/worker/service/non-availability.service';

@Injectable({
  providedIn: 'root'
})
export class FacadeService {
  private _contractService: ContractService;
  private get contractService(): ContractService {
    if (!this._contractService) {
      this._contractService = this.injector.get(ContractService);
    }
    return this._contractService;
  }

  private _invoiceService: InvoiceService;
  private get invoiceService(): InvoiceService {
    if (!this._invoiceService) {
      this._invoiceService = this.injector.get(InvoiceService);
    }
    return this._invoiceService;
  }

  private _customerService: CustomerService;
  private get customerService(): CustomerService {
    if (!this._customerService) {
      this._customerService = this.injector.get(CustomerService);
    }
    return this._customerService;
  }

  private _userService: UserService;
  private get userService(): UserService {
    if (!this._userService) {
      this._userService = this.injector.get(UserService);
    }
    return this._userService;
  }

  private _requestService: RequestService;
  private get requestService(): RequestService {
    if (!this._requestService) {
      this._requestService = this.injector.get(RequestService);
    }
    return this._requestService;
  }

  private _prestationService: PrestationService;
  private get prestationService(): PrestationService {
    if (!this._prestationService) {
      this._prestationService = this.injector.get(PrestationService);
    }
    return this._prestationService;
  }

  private _periodPrestationService: PrestationPeriodService;
  private get periodPrestationService(): PrestationPeriodService {
    if (!this._periodPrestationService) {
      this._periodPrestationService = this.injector.get(PrestationPeriodService);
    }
    return this._periodPrestationService;
  }

  private _nonAvailabilityService: NonAvailabilityService;
  private get nonAvailabilityService(): NonAvailabilityService {
    if (!this._nonAvailabilityService) {
      this._nonAvailabilityService = this.injector.get(NonAvailabilityService);
    }
    return this._nonAvailabilityService;
  }

  constructor(private injector: Injector) {
  }

  getNonAvailabilitiesFilter(workerId: number, startDate: Date, endDate: Date): Observable<any> {
    return this.nonAvailabilityService.filter(workerId, startDate, endDate);
  }

  getRequestById(id: number): Observable<any> {
    return this.requestService.get(id);
  }

  saveRequest(request: Request): Observable<any> {
    return this.requestService.save(request);
  }

  deleteRequest(request: Request): Observable<any> {
    return this.requestService.delete(request);
  }

  getRequestByCustomerIdAndStartDateAndEndDate(startDate: Date, endDate: Date): Observable<any> {
    return this.requestService.filter(startDate, endDate);
  }

  getPastWorkers() {
    return this.requestService.getPastWorkers();
  }

  authorize(login: Login): Observable<any> {
    return this.userService.authorize(login);
  }

  refreshToken(refreshToken: string): Observable<any> {
    return this.userService.refreshToken(refreshToken);
  }

  getUserFromToken(token: string): Observable<any> {
    return this.userService.getUserFromToken(token);
  }

  resetPassword(username: string): Observable<any> {
    return this.userService.resetPassword(username);
  }

  getUserByUuid(uuid: string): Observable<any> {
    return this.userService.getUserByUuid(uuid);
  }

  updateUserPassword(password: string, newPassword: string, username: string, code?: string): Observable<any> {
    return this.userService.updatePassword(password, newPassword, username, code);
  }

  listInvoices(): Observable<any> {
    return this.invoiceService.listInvoices();
  }

  getInvoicePdf(invoice: Invoice, language: string): Observable<any> {
    return this.invoiceService.downloadPdf(invoice, language);
  }

  getCustomer(): Observable<any> {
    return this.customerService.getCustomer();
  }

  listContracts(): Observable<any> {
    return this.contractService.listContracts();
  }

  getContractOriginalFile(contract: Contract, language: string): Observable<any> {
    return this.contractService.downloadPdf(contract, language);
  }

  getAllPrestationsToValidate(): Observable<any> {
    return this.prestationService.listToValidate();
  }

  validatePrestation(prestation: Prestation): Observable<any> {
    return this.prestationService.validate(prestation);
  }

  validatePrestationPeriod(period: PrestationPeriod): Observable<any> {
    return this.periodPrestationService.validate(period);
  }

  rejectPrestationPeriod(period: PrestationPeriod, file: File): Observable<any> {
    return this.periodPrestationService.reject(period, file);
  }

  listWorkFunctions(): Observable<any> {
    return this.customerService.listWorkFunctions();
  }

  getPrestationCodeJustification(): Observable<any> {
    return this.customerService.listPrestationCodeJustifications();
  }

  filterPrestation(startDate: Date, endDate: Date, workerId: number, customerId: number) {
    return this.prestationService.filter(startDate, endDate, workerId, customerId);
  }
}
