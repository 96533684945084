import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { NonAvailabilitySerializer } from 'src/app/shared/model/non-availability/nonAvailability.serializer';
import { DateSerializer } from 'src/app/shared/model/date-serializer';
import { NonAvailability } from 'src/app/shared/model/non-availability/nonAvailability.model';

@Injectable({
  providedIn: 'root'
})
export class NonAvailabilityService {
  private serviceURL = environment.worker_service_URL + 'non-availabilities';
  private nonAvailabilitySerializer: NonAvailabilitySerializer = new NonAvailabilitySerializer();
  private dateSerializer: DateSerializer = new DateSerializer();

  constructor(private http: HttpClient) {
  }

  filter(workerId: number, startDate: Date = null, endDate: Date = null): Observable<any> {
    let params = new HttpParams();
    params = params.append('workerId', String(workerId));
    if (startDate) {
      params = params.append('startDate', this.dateSerializer.toJson(startDate));
    }
    if (endDate) {
      params = params.append('endDate', this.dateSerializer.toJson(endDate));
    }
    return this.http.get<NonAvailability[]>(this.serviceURL + '/filter', {params}).pipe(
      map((data) => this.convertData(data))
    );
  }

  save(nonAvailability: NonAvailability): Observable<any> {
    return this.http.post<NonAvailability>(
      this.serviceURL, this.nonAvailabilitySerializer.toJson(nonAvailability)).pipe(
      map((data) => this.nonAvailabilitySerializer.fromJson(data))
    );
  }

  delete(nonAvailability: NonAvailability): Observable<any> {
    return this.http.delete<NonAvailability>(this.serviceURL + '/' + nonAvailability.id);
  }

  private convertData(data: any): NonAvailability[] {
    return data.map(nonAvailability => this.nonAvailabilitySerializer.fromJson(nonAvailability));
  }
}
