export class Worker {
  id: number;
  firstName: string;
  lastName: string;
  mobile: string;
  customerRemarks: string;

  get fullName(): string {
    
    let firstName = this.firstName ? this.firstName : '';
    let lastName = this.lastName ? this.lastName : '';
    
    return `${firstName} ${lastName}`;
  }
}
