import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {HttpStateService} from './http-state.service';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, retry, switchMap, take, tap} from 'rxjs/operators';
import {HttpProgressState} from '../enum/http-progress-state .enum';
import {ErrorDialogService} from '../../errors/error-dialog.service';
import {AuthorizationService} from '../../../../login/shared/service/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  private exceptions: string[] = [
    'login',
    'refresh-token'
  ];

  private refreshing$ = new BehaviorSubject<boolean>(false);

  constructor(private httpStateService: HttpStateService,
              private authorizationService: AuthorizationService,
              private errorDialogService: ErrorDialogService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.exceptions.every((term: string) => request.url.indexOf(term) === -1)) {
      return next.handle(request).pipe(tap(ignoreProperty => {
        },
        ignoreProperty => {
        }));
    }

    this.httpStateService.state.next({
      url: request.url,
      state: HttpProgressState.start
    });

    if (request.url.indexOf('/refresh-token') !== -1) {
      return next.handle(request);
    }

    request = request.clone(this.setHeaders());

    return next.handle(request)
      .pipe(
        retry(1),
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              return this.handle401Error(request, next);
            }
            if (error.error) {

              if (error.error.message) {
                this.errorDialogService.openDialog(error.error.message);
              }

              return throwError(() => error);
            }
          }
        }),
        finalize(() => {
          this.httpStateService.state.next({
            url: request.url,
            state: HttpProgressState.end
          });
        })
      );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.refreshing$.getValue()) {
      this.refreshing$.next(true);
      this.authorizationService.refreshToken().subscribe({
        next: res => {
          const token = res.headers.get('authorization');
          const refreshToken = res.headers.get('refresh_token');
          this.authorizationService.setToken(token, refreshToken);
          this.refreshing$.next(false);
        },
        error: () => {
          this.authorizationService.logout();
        }
      });
    }

    return this.refreshing$.pipe(
      filter(refreshing => refreshing === false), take(1),
      switchMap(() => this.authorizationService.getToken()
        ? next.handle(request.clone(this.setHeaders()))
        : throwError(() => new Error('Not Authorized')))
    );
  }

  private setHeaders() {
    return {
      setHeaders: {
        'Authorization': this.authorizationService.getToken(),
      }
    };
  }
}
