import {Worker} from '../worker/worker.model';
import {RRule} from 'rrule';

export enum Frequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export enum EventType {
  NONAVAILABILITY,
  REQUEST,
  PERIOD
}


export class NonAvailability {

  id: number;
  worker: Worker;
  startDate: Date;
  endDate: Date;
  until: Date;
  active: boolean;
  frequency: Frequency;
  count: number;
  allDay: boolean;


  static buildEvent(nonAvailability: NonAvailability) {

    const start = nonAvailability.startDate;
    const end = nonAvailability.endDate;
    const allDay = nonAvailability.allDay ?? false;
    const freq = nonAvailability.frequency;
    const until = nonAvailability.until;

    const event = {
      title: 'Off',
      nonAvailability: nonAvailability,
      color: 'red',
      overlap: false,
      eventType: EventType.NONAVAILABILITY
    };

    if (until && freq) {
      event['duration'] = this.getDuration(start, end, allDay);
      event['rrule'] = {
        freq: this.getFrequency(freq),
        dtstart: start.toISOString(),
        until: until.toISOString().split('T')[0]
      };
    } else {
      event['start'] = start;
      event['end'] = end;
    }
    return event;
  }

  static getDuration(start: Date, end: Date, allDay: boolean): string {
    const diff = end.getTime() - start.getTime();
    const total_seconds = Math.floor(diff / 1000);
    const total_minutes = Math.floor(total_seconds / 60);
    const total_hours = Math.floor(total_minutes / 60);
    const min = (total_minutes - total_hours * 60).toString();
    const duration = `${total_hours}:${min.length === 1 ? '0' + min : min}`;
    return allDay || duration === '24:00' ? `23:59` : duration;
  }

  static getFrequency(freq: Frequency) {
    if (freq) {
      switch (freq) {
        case Frequency.WEEKLY:
          return RRule.WEEKLY;
        case Frequency.MONTHLY:
          return RRule.MONTHLY;
        default:
          return RRule.DAILY;
      }
    }
  }

  isValid(): boolean {
    if ((this.endDate.getTime() - this.startDate.getTime()) < 0) {
      return false;
    }

    if (!this.worker) {
      return false;
    }

    return true;
  }
}
