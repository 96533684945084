import {Component, Inject} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-event-dialog',
  templateUrl: './event-dialog.component.html',
  styleUrls: ['./event-dialog.component.scss']
})
export class EventDialogComponent {
  description: string;
  eventId: number;

  constructor(private dialogRef: MatDialogRef<EventDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    if (data.description) {
      this.description = data.description;
    }
    if (data.eventId) {
      this.eventId = data.eventId;
    }
  }

  submit(form: NgForm) {
    this.dialogRef.close([form.value.description, false]);
  }

  close() {
    this.dialogRef.close();
  }

  delete(eventId: number) {
    this.dialogRef.close([null, eventId]);
  }

}
