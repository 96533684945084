<div class="bg-horeca-log">

  <div class="container">

    <div class="row">

      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

        <div class="card card-signin my-5 shadowbox">


          <!-- Brand Logo -->
          <div class="card-img-top bggradient-1">
            <img class="logoHS" src="../assets/img/HS_Horeca_2022_RVB_72dpi.png" alt="Human Support Horeca">
          </div>

          <!-- Title -->
          <div class="card-img-top bgcolor-1">
            <h4 class="text-center">{{ 'login.resetPassword.title' | translate }}</h4>
          </div>

          <div class="card-body">


            <div *ngIf="user.username" >


              <form #changePasswordForm="ngForm" (ngSubmit)="changePassword(changePasswordForm)">

                  <div class="form-group">

                    <div class="form-row">

                      <div class="col-12">

                        <div class="matFlex">

                          <div class="form-group">

                            <div class="col-12">

                              <div class="matFlex">

                                <mat-form-field appearance="outline">
                                  <mat-label>{{ 'user.form.changePasswordForm.newPassword' | translate }}</mat-label>
                                  <input id="newPassword" matInput name="newPassword" ngModel required type="password"/>
                                  <mat-error>
                                    {{ 'user.form.changePasswordForm.message.error.newPassword' | translate }}
                                  </mat-error>
                                </mat-form-field>

                              </div>

                            </div>

                            <div class="col-12">

                              <div class="matFlex">

                                <mat-form-field appearance="outline">
                                  <mat-label>{{ 'user.form.changePasswordForm.confirmNewPassword' | translate }}</mat-label>
                                  <input id="confirmNewPassword" matInput name="confirmNewPassword" ngModel required
                                         type="password"/>
                                  <mat-error>
                                    {{ 'user.form.changePasswordForm.message.error.confirmNewPassword' | translate }}
                                  </mat-error>
                                </mat-form-field>

                              </div>

                            </div>

                          </div>


                          <!-- Login Button -->
                          <button class="btn btn-lg btn-danger btn-block my-4"
                                  type="submit">{{ ('login.resetPassword.changePassword' | translate) | uppercase  }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

              </form>

            </div>

            <div *ngIf="!user.username" [innerHTML]="'login.resetPassword.message.error.invalidCode' | translate ">
            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>

