import {Serializer} from '../../../shared/model/serializer.interface';
import {DateSerializer} from '../../../shared/model/date-serializer';
import {Invoice} from './invoice.model';

export class InvoiceSerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();

  fromJson(json: any): Invoice {
    const invoice: Invoice = new Invoice();

    invoice.id = json.id;
    invoice.totalVATExcluded = json.totalVATExcluded;
    invoice.totalVATIncluded = json.totalVATIncluded;
    invoice.invoicingDate = this.dateSerializer.fromJson(json.invoicingDate);
    invoice.dueDate = this.dateSerializer.fromJson(json.dueDate);

    return invoice;
  }

  toJson(invoice: Invoice): any {}

}
