<div class="bg-horeca-log">

  <div class="container">

    <div class="row">

      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

        <div class="card card-signin my-5 shadowbox">


            <!-- Brand Logo -->
            <div class="logoHS">
              <img src="../assets/img/HS_Horeca_2022_RVB_72dpi.png" alt="Human Support Horeca">
            </div>

            <!-- Title -->
            <div class="card-img-top bgcolor-1">
              <h4 class="loginTitle">{{ 'login.resetPassword.title' | translate }}</h4>
            </div>

            <div class="card-body" *ngIf="!usernameValidated">

              <form [formGroup]="usernameForm" (ngSubmit)="onSubmit()">


                <div class="form-group">

                  <div class="form-row">

                    <div class="col-12">

                      <div class="matFlex">

                        <div>
                          {{ ('login.resetPassword.instructions' | translate) }}
                        </div>

                        <mat-form-field appearance="outline">
                          <mat-label>{{ 'login.username' | translate }}</mat-label>
                          <input matInput formControlName="username" type="text" id="username" #username required>
                          <mat-error>{{ 'login.resetPassword.message.error.invalidUsername' | translate }}
                          </mat-error>
                        </mat-form-field>


                        <div class="container">

                          <div class="row">

                            <!-- Back to login -->
                            <div class="col-12 text-right pwstxt">
                              <a href="/login">{{ 'login.resetPassword.backToLogin' | translate }}</a>
                            </div>

                          </div>

                        </div>

                        <!-- Send reset password request -->
                        <button class="btn btn-lg btn-danger btn-block my-4" type="submit">{{ ('login.resetPassword.send' | translate ) | uppercase  }}
                        </button>

                      </div>

                    </div>

                  </div>

                </div>

              </form>

            </div>

            <div class="card-body" *ngIf="usernameValidated">

              <div
                [innerHTML]="'login.resetPassword.message.success' | translate: {username: usernameForm.get('username').value}">
              </div>

              <!-- Login Button -->
              <button class="btn btn-lg btn-danger btn-block my-4" type="submit" (click)="backToMenu();">
                {{ ('login.resetPassword.return' | translate)  }}
              </button>

            </div>

        </div>

      </div>

    </div>

  </div>

</div>
