import {Component} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FacadeService} from '../shared/service/facade/facade.service';
import {AuthorizationService} from '../login/shared/service/authorization.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {

  constructor(private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private authorizationService: AuthorizationService) {
  }

  changePassword(form: NgForm) {
    if (!form.valid) {
      return this.openSnackBar(this.translate.instant('user.message.error.form'));
    }
    const oldPassword = form.value.oldPassword.trim();
    const newPassword = form.value.newPassword.trim();
    const confirmPassword = form.value.confirmPassword.trim();

    if (newPassword !== confirmPassword) {
      return this.openSnackBar(this.translate.instant('user.message.error.different'));
    }

    const username = this.authorizationService.getCurrentUser().username;
    this.facadeService.updateUserPassword(newPassword, oldPassword, username)
      .subscribe({
        next: ignore => {
          this.openSnackBar(this.translate.instant('user.message.success.update'), 'customSnackValid');
          return this.authorizationService.logout();
        },
        error: ignore => {
          this.openSnackBar(this.translate.instant('user.message.error.update'));
        }
      });
  }

  private openSnackBar(message: string, pC: string = 'customSnackError') {
    this.snackBar.open(message, 'X', {
      duration: 5000,
      panelClass: [pC]
    });
  }
}
