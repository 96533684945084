import {WorkFunction} from './work-function.model';
import {Serializer} from '../serializer.interface';
import { TranslateService } from '@ngx-translate/core';
import { inject } from '@angular/core';

export class WorkFunctionSerializer implements Serializer {

  translateService: TranslateService = inject(TranslateService)
  
  fromJson(json: any): WorkFunction {
    const workFunction: WorkFunction = new WorkFunction();
    workFunction.id = json.id;
    workFunction.category = json.category;
    workFunction.code = json.code;
    workFunction.nameDE = json.nameDE;
    workFunction.nameEN = json.nameEN;
    workFunction.nameFR = json.nameFR;
    workFunction.nameNL = json.nameNL;

    workFunction.nameTranslate = this.translateService.currentLang === "fr" ? json.nameFR : this.translateService.currentLang === "nl" ? json.nameNL : json.nameEN ;

    return workFunction;
  }

  toJson(workFunction: WorkFunction): any {
    return {};
  }
}
