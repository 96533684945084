import {DatePipe} from '@angular/common';
import {Serializer} from './serializer.interface';

export class DateSerializer implements Serializer {
  private datePipe: DatePipe = new DatePipe('en');

  fromJson(date: Date): Date {
    return (date) ? new Date(date) : null;
  }

  hoursFromJson(date: string): Date {
    if (date) {
      const hours = date.split(':');
      return new Date(new Date(0, 0, 0).setHours(+hours[0], +hours[1]));
    }
    return null;
  }

  toJson(date: any) {
    return (date) ? this.datePipe.transform(date, 'yyyy-MM-dd') : null;
  }

  dateWithTimeToJson(date: any) {
    return (date) ? this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss') : null;
  }

  dateWithTimeSSSToJson(date: any) {
    return (date) ? this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss.SSS') : null;
  }

  hoursToJson(date: any) {
    return (date) ? [date.getHours(), date.getMinutes()] : null;
  }
}
