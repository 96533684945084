import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {PrestationSerializer} from '../model/prestation.serializer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Prestation} from '../model/prestation.model';
import { DateSerializer } from 'src/app/shared/model/date-serializer';

@Injectable({
  providedIn: 'root'
})
export class PrestationService {
  private serviceURL = environment.prestation_service_URL + 'prestations';
  private prestationSerializer: PrestationSerializer = new PrestationSerializer();
  private dateSerializer: DateSerializer = new DateSerializer();

  constructor(private http: HttpClient) {
  }

  listToValidate(): Observable<any> {
    return this.http.get<Prestation[]>(this.serviceURL + '/customer/to-validate').pipe(
      map((data) => this.convertData(data))
    );
  }

  validate(prestation: Prestation): Observable<any> {
    return this.http.post<Prestation>(this.serviceURL + `/customer/validate/${prestation.id}`, {}).pipe(
      map((data) => this.prestationSerializer.fromJson(data))
    );
  }

  private convertData(data: any): Prestation[] {
    return data.map(prestation => this.prestationSerializer.fromJson(prestation));
  }

  filter(startDate: Date, endDate: Date, workerId: number, customerId: number): Observable<any> {

    let params = this.getBasicFilter(startDate, endDate, workerId, customerId);
    params = params.append('active', String(true));

    return this.http.get<Prestation[]>(this.serviceURL + '/filter', {params}).pipe(
      map((data) => this.convertData(data))
    );
  }

  private getBasicFilter(startDate: Date, endDate: Date, workerId: number, customerId: number): HttpParams {
    let params = new HttpParams();

    if (workerId) {
      params = params.append('workerId', String(workerId));
    }
    if (startDate) {
      params = params.append('from', this.dateSerializer.toJson(startDate));
    }
    if (endDate) {
      params = params.append('to', this.dateSerializer.toJson(endDate.setDate(endDate.getDate() + 1)));
    }
    if (customerId) {
      params = params.append('customerId', String(customerId));
    }

    return params;
  }
}
