import {Country} from './country.model';
import {Serializer} from '../serializer.interface';

export class CountrySerializer implements Serializer {
  fromJson(json: any): Country {
    const country: Country = new Country();
    country.code = json.code;
    country.nameDE = json.nameDE;
    country.nameEN = json.nameEN;
    country.nameFR = json.nameFR;
    country.nameNL = json.nameNL;
    return country;
  }

  toJson(country: Country): any {}
}
