import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FacadeService} from '../../shared/service/facade/facade.service';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../user/shared/model/user.model';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.css']
})
export class EditPasswordComponent implements OnInit {

  user: User;
  uuid: string;

  constructor(private route: ActivatedRoute,
              private facadeService: FacadeService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.uuid = this.route.snapshot.queryParamMap.get('uuid');
    this.user = this.route.snapshot.data['user'];
    const language = this.route.snapshot.queryParamMap.get('language');
    if (language) {
      this.translate.use(language.toLowerCase());
    }
  }

  changePassword(form: NgForm) {
    if (!form.valid) {
      return alert(this.translate.instant('user.form.changePasswordForm.message.error.form'));
    }
    const newPassword = form.value.newPassword.trim();
    const confirmPassword = form.value.confirmNewPassword.trim();

    if (newPassword !== confirmPassword) {
      return alert(this.translate.instant('user.form.changePasswordForm.message.error.different'));
    }

    this.facadeService.updateUserPassword('', newPassword, this.user.username, this.uuid)
      .subscribe(
        ignoreProperty => {
          alert(this.translate.instant('user.form.changePasswordForm.message.success.save'));
          window.location.href = '/login';
        },
        ignoreProperty => {
          alert(this.translate.instant('user.form.changePasswordForm.message.error.save'));
        }
      );
  }

}
