<div class="bg-horeca-log">

  <div class="container">

    <div class="row">

      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

        <div class="card card-signin my-5 shadowbox">

          <form #cForm="ngForm" (ngSubmit)="onSubmit()">

            <!-- Brand Logo -->
            <div class="logoHS">
              <img src="../assets/img/HS_Horeca_2022_RVB_72dpi.png" alt="Human Supports Horeca">
            </div>

            <!-- Title -->
            <div class="card-img-top bgcolor-1">

              <h4 class="loginTitle">{{ 'login.title' | translate }}</h4>

            </div>

            <div class="card-body">

              <!-- LOG -->
              <div class="form-group">

                <input [(ngModel)]="login.username" autofocus class="form-control" id="login.username"
                       name="login.username" placeholder="{{ 'login.username' | translate }}"
                       required type="email">
              </div>

              <div class="form-group">
                <input [(ngModel)]="login.password" class="form-control" id="login.password" name="login.password"
                       placeholder="{{ 'login.password' | translate }}"
                       required type="password">
              </div>

              <div class="container">

                <div class="row">

                  <!-- Remember Me -->
                  <div class="custom-control custom-checkbox mb-3 col-8">

                    <!-- <div class="custom-control custom-checkbox mb-3 col-8">
                         <input class="custom-control-input" id="customCheck1" type="checkbox" [checked]="login.rememberMe" (change)="login.rememberMe = !login.rememberMe">
                         <label class="custom-control-label"
                                for="customCheck1">{{ 'login.rememberPassword' | translate }}</label>
                         </div> -->

                    <mat-checkbox [checked]="login.rememberMe" (change)="login.rememberMe = !login.rememberMe">
                      {{ 'login.rememberPassword' | translate }}
                    </mat-checkbox>
                  </div>

                  <!-- Forgot Password -->
                  <div class="col-4 text-right pwstxt"><a
                    href="/send-password-reset">{{ 'login.lostPassword' | translate }}</a></div>

                </div>

              </div>

              <!-- Login Button -->
              <button class="btn btn-lg btn-danger btn-block my-4" type="submit">
                <i class="fas fa-lock"></i>&nbsp;&nbsp;{{ ('login.connection' | translate) | uppercase }}
              </button>

            </div>

            <div *ngIf="lang == 'fr-FR' || lang == 'fr'" class="card-footer text-muted text-center">
              <a
                href="https://storage.googleapis.com/hs-production-public-document/FR/Human_Support_Privacy_Policy.pdf"><u>{{
                'login.policy' | translate }}</u></a>
            </div>
            <div *ngIf="lang == 'en-US' || lang == 'en-UK' || lang == 'en'" class="card-footer text-muted text-center">
              <a
                href="https://storage.googleapis.com/hs-production-public-document/EN/Human_Support_Privacy_Policy.pdf"><u>{{
                'login.policy' | translate }}</u></a>
            </div>
            <div *ngIf="lang == 'nl-NL' || lang == 'nl'" class="card-footer text-muted text-center">
              <a
                href="https://storage.googleapis.com/hs-production-public-document/NL/Human_Support_Privacy_Policy.pdf"><u>{{
                'login.policy' | translate }}</u></a>
            </div>
          </form>

        </div>

      </div>

    </div>

  </div>

</div>
