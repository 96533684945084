export const environment = {
  env_name: 'T',
  production: false,
  billing_service_URL: 'https://hs-test-api.comexis.net/billing-service/',
  customer_service_URL: 'https://hs-test-api.comexis.net/customer-service/',
  auth_service_URL: 'https://hs-test-api.comexis.net/',
  contract_service_URL: 'https://hs-test-api.comexis.net/contract-service/',
  request_service_URL: 'https://hs-test-api.comexis.net/request-service/',
  prestation_service_URL: 'https://hs-test-api.comexis.net/prestation-service/',
  worker_service_URL: 'https://hs-test-api.comexis.net/worker-service/',
};
