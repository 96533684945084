import {Worker} from './worker.model';
import {Serializer} from '../serializer.interface';

export class WorkerSerializer implements Serializer {

  fromJson(json: any): any {
    const worker: Worker = new Worker();

    worker.id = json.id;
    worker.firstName = json.firstName;
    worker.lastName = json.lastName;
    worker.mobile = json.mobile;
    worker.customerRemarks = json.customerRemarks;

    return worker;
  }

  toJson(worker: Worker): any {
    return {};
  }
}
