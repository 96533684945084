import {Address} from '../../../shared/model/address/address.model';
import {OperatingUnit} from '../../../shared/model/operating-unit/operating-unit.model';
import {Country} from '../../../shared/model/country/country.model';

export class Customer {
  id: number;
  enterpriseNumber: string;
  name: string;
  prefix: string;
  address: Address = new Address();
  billingAddress: Address = new Address();
  operatingUnit?: OperatingUnit = new OperatingUnit();
  costCenter: [string] = [""];
  billingCountry: Country;
  addressCountry: Country;
}
