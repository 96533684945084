import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module';
import {NotFoundComponent} from './not-found/not-found.component';
import {LoginComponent} from './login/login.component';
import {CustomerComponent} from './customer/customer.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {PlanningComponent} from './planning/planning.component';
import {ContractsComponent} from './contracts/contracts.component';
import {PrestationsComponent} from './prestations/prestations.component';
import {InvoicesComponent} from './invoices/invoices.component';
import {FacadeService} from './shared/service/facade/facade.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthorizationService} from './login/shared/service/authorization.service';
import {APP_BASE_HREF, DatePipe, registerLocaleData} from '@angular/common';
import {SpinnerComponent} from './shared/component/spinner/spinner.component';
import {InterceptorService} from './shared/component/spinner/service/interceptor.service';
import {MatDialogModule} from '@angular/material/dialog';
import {UserComponent} from './user/user.component';
import {RequestComponent} from './request/request.component';
import {EventDialogComponent} from './request/event-dialog/event-dialog.component';
import {PrestationValidationDialogComponent} from './prestations/prestation-validation-dialog/prestation-dialog.component';
import {ResolverModule} from './resolver.module';
import {PrestationRejectDialogComponent} from './prestations/prestation-reject-dialog/prestation-dialog.component';
import {ValidatorGuardService} from './login/shared/service/validator-guard.service';
import localeFr from '@angular/common/locales/fr';
import {RegisterComponent} from './register/register.component';
import {EditPasswordComponent} from './reset-password/edit-password/edit-password.component';
import {RequestResetPasswordComponent} from './reset-password/request-reset-password/request-reset-password.component';
import {ErrorDialogService} from './shared/component/errors/error-dialog.service';
import {ErrorDialogComponent} from './shared/component/errors/error-dialog/error-dialog.component';
import {appInitializer} from './login/shared/service/auto-login.service';
import {AsTypePipe} from './shared/pipe/as-type.pipe';
import {FullCalendarModule} from '@fullcalendar/angular';


registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoginComponent,
    CustomerComponent,
    PlanningComponent,
    ContractsComponent,
    PrestationsComponent,
    InvoicesComponent,
    SpinnerComponent,
    UserComponent,
    RequestComponent,
    EventDialogComponent,
    PrestationValidationDialogComponent,
    PrestationRejectDialogComponent,
    RegisterComponent,
    EditPasswordComponent,
    RequestResetPasswordComponent,
    ErrorDialogComponent,
    AsTypePipe
  ],
  imports: [
    BrowserModule,
    FullCalendarModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    ResolverModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'fr-BE'
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthorizationService]
    },
    FacadeService,
    AuthorizationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    ErrorDialogService,
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    DatePipe,
    ValidatorGuardService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
