import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Invoice} from '../model/invoice.model';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {InvoiceSerializer} from '../model/invoice.serializer';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private serviceURL = environment.billing_service_URL + 'invoices';
  private invoiceSerializer: InvoiceSerializer = new InvoiceSerializer();

  constructor(private http: HttpClient) {
  }

  listInvoices(): Observable<any> {
    return this.http.get<Invoice[]>(`${this.serviceURL}/app/customer/invoices`).pipe(
      map((data) => this.convertData(data))
    );
  }

  downloadPdf(invoice: Invoice, language: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('language', String(language));
    return this.http.get( `${this.serviceURL}/app/customer/invoice/${invoice.id}/pdf`, {params: params, responseType: 'blob'});
  }

  private convertData(data: any): Invoice[] {
    return data.map(invoice =>
      this.invoiceSerializer.fromJson(invoice));
  }
}
