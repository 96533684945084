import {User} from './user.model';
import {Serializer} from '../../../shared/model/serializer.interface';

export class UserSerializer implements Serializer {
  fromJson(json: any): any {
    const user: User = new User();

    user.id = json.id;
    user.username = json.username;
    user.languageCode = json.languageCode;
    user.role = json.roleName;

    return user;
  }

  toJson(user: User): any {
    return {
      username: user.username,
      password: user.password
    };
  }
}

