import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../model/user.model';
import {map} from 'rxjs/operators';
import {UserSerializer} from '../model/user.serializer';
import {Login} from '../../../login/shared/model/login.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private authServiceURL = environment.auth_service_URL + 'auth';
  private userSerializer: UserSerializer = new UserSerializer();

  constructor(private http: HttpClient) {
  }

  authorize(login: Login): Observable<any> {

    const loginJson = {
      'username': login.username,
      'password': login.password
    };

    return this.http.post<any>(this.authServiceURL + '/login', loginJson, {observe: 'response'});
  }

  refreshToken(refreshToken: string): Observable<any> {
    return this.http.get<any>(this.authServiceURL + '/refresh-token?token=' + refreshToken, {observe: 'response'});
  }

  getUserFromToken(token: string): Observable<any> {

    const authorizationHeader = {
      'Authorization': token,
    };

    const requestOptions = {
      headers: new HttpHeaders(authorizationHeader),
    };

    return this.http.get<User>(this.authServiceURL + '/app/customer/user', requestOptions).pipe(
      map((data) => this.userSerializer.fromJson(data))
    );
  }

  resetPassword(username: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('username', username);
    return this.http.get(this.authServiceURL + '/reset-password', {params});
  }

  updatePassword(password: string, newPassword: string, username: string, uuid?: string): Observable<any> {
    const body = {
      'username': username,
      'password': password,
      'newPassword': newPassword
    };
    let params = new HttpParams();
    if (uuid) {
      params = params.append('uuid', uuid);
    }
    return this.http.post(this.authServiceURL + '/update-password', body, {params: params});
  }

  getUserByUuid(uuid: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('uuid', uuid);
    return this.http.get<User>(this.authServiceURL + '/load-by-uuid', {params}).pipe(
      map((data) => this.userSerializer.fromJson(data))
    );
  }
}
