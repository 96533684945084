import {Prestation} from './prestation.model';
import {PrestationPeriodSerializer} from './prestation-period.serializer';
import {PrestationPeriod} from './prestation-period.model';
import {DateSerializer} from '../../../shared/model/date-serializer';
import {Serializer} from '../../../shared/model/serializer.interface';

export class PrestationSerializer implements Serializer {
  private periodSerializer: PrestationPeriodSerializer = new PrestationPeriodSerializer();
  private dateSerializer: DateSerializer = new DateSerializer();

  fromJson(json: any): Prestation {
    const prestation: Prestation = new Prestation();
    const periods: PrestationPeriod[] = [];

    prestation.id = json.id;
    prestation.requestId = json.requestId;
    prestation.jointCommissionCode = json.jointCommissionCode;
    prestation.customerName = json.customerName;
    prestation.workerFirstName = json.workerFirstName;
    prestation.workerLastName = json.workerLastName;
    prestation.startDate = this.dateSerializer.fromJson(json.startDate);
    prestation.endDate = this.dateSerializer.fromJson(json.endDate);
    const jsonPeriods = json.periods;
    if (jsonPeriods) {
      jsonPeriods.forEach(
        jsonPeriod => {
          const period = this.periodSerializer.fromJson(jsonPeriod);
          periods.push(period);
        });
    }
    prestation.periods = periods;

    return prestation;
  }

  toJson(prestation: Prestation): any {}
}
